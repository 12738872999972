import { convertDistance, getDistance } from "geolib"
import React, { useEffect, useRef, useState } from "react"
import Layout from "../../../components/layout"
import useAPI from "../../../hooks/useAPI"
import useFirebaseWrapper from "../../../hooks/useFirebaseWrapper"
import useSiteMetadata from "../../../hooks/useSiteMetadata"
import { confirmPromise, Fa } from "../../../utils"
import AttendeeForm from "../components/AttendeeForm"
import HostListItem from "../components/HostListItem"
import HostLocationOnMap from "../components/HostLocationOnMap"
import useLocation from "../hooks/useLocation"
import useValidateMeetupStatus from "../hooks/useValidateMeetupStatus"

// -- FUNCTIONS
const sortDistance = (a, b) => {
  if (a.distance > b.distance) {
    return 1
  }
  if (a.distance < b.distance) {
    return -1
  }
  return 0
}

// -- MAIN
const AttendeePage = () => {
  const { edition } = useSiteMetadata()
  const {
    getAllMeetupsHost,
    getHostDetails,
    attendeeJoinMeetup,
    attendeeChangeHost,
    sendConfirmationEmail,
  } = useAPI()
  const { coords, error: locationError } = useLocation()
  const {
    isRegisteredAsAttendee,
    isRegisteredAsHost,
    hostId,
    attendeeId,
  } = useValidateMeetupStatus()
  const { isLoggedIn } = useFirebaseWrapper()

  const [allHosts, setAllHosts] = useState([])
  const [isLoadingAllHosts, setIsLoadingAllHosts] = useState(true)
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedHost, setSelectedHost] = useState({})
  const [appliedHost, setAppliedHost] = useState(null)
  const [isLoadingAppliedHost, setIsLoadingAppliedHost] = useState(false)

  const appliedStatus = appliedHost?.attendees?.find(
    x => x.attendee_id === attendeeId
  )?.status

  const mapRef = useRef(null)

  useEffect(() => {
    const getHostsPromise = getAllMeetupsHost({ edition })

    if (!getHostsPromise) {
      return
    }

    getHostsPromise
      .then(res => res.json())
      .then(resJson => {
        setAllHosts(
          resJson.data
            .map(x => ({
              ...x,
              distance: coords
                ? convertDistance(
                    getDistance(
                      { latitude: coords.lat, longitude: coords.lng },
                      {
                        latitude: Number(x.location.coords.lat),
                        longitude: Number(x.location.coords.lng),
                      }
                    ),
                    "mi"
                  )
                : null,
            }))
            .sort(sortDistance)
        )
        setIsLoadingAllHosts(false)
      })
  }, [edition, getAllMeetupsHost, coords])

  useEffect(() => {
    if (isRegisteredAsAttendee && hostId) {
      const getHostPromise = getHostDetails({ edition, hostId })

      if (!getHostPromise) {
        return
      }

      setIsLoadingAppliedHost(true)

      getHostPromise
        .then(res => res.json())
        .then(resJson => {
          // remove applied host out of the list
          setAllHosts(prev =>
            prev.filter(x => x.host_id !== resJson.data.host_id)
          )

          setAppliedHost({
            ...resJson.data,
            distance: coords
              ? convertDistance(
                  getDistance(
                    { latitude: coords.lat, longitude: coords.lng },
                    {
                      latitude: Number(resJson.data.location.coords.lat),
                      longitude: Number(resJson.data.location.coords.lng),
                    }
                  ),
                  "mi"
                )
              : null,
          })
          setIsLoadingAppliedHost(false)
        })
    }
  }, [
    isRegisteredAsAttendee,
    hostId,
    getHostDetails,
    edition,
    coords,
    attendeeId,
  ])

  return (
    <Layout>
      <AttendeeForm
        modalControl={[modalVisible, setModalVisible]}
        hostData={selectedHost}
        onSubmit={async (data, setIsSending) => {
          let requester

          if (attendeeId) {
            try {
              await confirmPromise(
                "If you change the host, you have to wait for the new host to accept."
              )
              requester = attendeeChangeHost({
                edition,
                payload: {
                  ...data,
                  status: "Pending",
                },
                attendeeId,
              })
            } catch (err) {
              console.log("[confirmPromise]", "user canceled")
              return
            }
          } else {
            requester = attendeeJoinMeetup({ edition, payload: data })
          }

          setIsSending()
          requester.then(() => {
            sendConfirmationEmail({
              type: "attendee-signup",
              payload: { host_id: selectedHost.hostId },
            })
            window.location.reload()
          })
        }}
      />
      <h2>Attendee sign-ups</h2>
      {isLoggedIn === false ? (
        <p
          css={`
            text-align: center;
            border: 2px solid ${p => p.theme.colors.danger};
            color: ${p => p.theme.colors.danger};
            padding: 1em;
          `}
        >
          You need to log-in before joining local meetups.
        </p>
      ) : null}
      {locationError ? (
        <p
          css={`
            text-align: center;
            border: 2px solid ${p => p.theme.colors.warning};
            color: ${p => p.theme.colors.warning};
            font-size: 0.8em;
            padding: 1em;
          `}
        >
          <b
            css={`
              color: ${p => p.theme.colors.warning};
            `}
          >
            {locationError.toUpperCase()}
          </b>
          <br />
          For a better user experience, please allow location permission so that
          we can use your location to calculate the nearest hosts as well as the
          directions to the host you have chosen.
        </p>
      ) : null}
      <p>
        You can find all the listings of local meetups and descriptions below.
        Simply find a meetup you are interested to join and fill out the form.
        After that, you can wait for the host to accept (or reject). After
        getting accepted, you will see full information of your host. Then you
        and your host can connect! Before joining any local meetup event, please
        read{" "}
        <a href="/instructions/how-to-meetups" target="_blank" rel="noreferrer">
          this page
        </a>{" "}
        carefully.
      </p>
      {isLoadingAppliedHost ? (
        <p css="text-align: center; margin-top: 1.56rem;">
          <Fa icon="sync" spin />
        </p>
      ) : appliedHost ? (
        <>
          {appliedStatus === "Pending" ? (
            <h4>
              You have applied to a meetup. Please wait for the host to respond!
            </h4>
          ) : appliedStatus === "Accepted" ? (
            <h4>You have been accepted to a meetup!</h4>
          ) : (
            <h4>
              The host rejected your application, maybe due to the limiting
              space or other reasons. Don't be sad, you can re-apply to the new
              host!
            </h4>
          )}
          <HostListItem
            appliedStatus={appliedStatus}
            myLocation={coords}
            key={JSON.stringify(appliedHost)}
            data={appliedHost}
            onViewOnMap={() => {
              mapRef.current.flyTo({
                center: [
                  Number(appliedHost.location.coords.lng),
                  Number(appliedHost.location.coords.lat),
                ],
                zoom: 15,
              })
            }}
            onPressJoin={() => {}}
          />
        </>
      ) : null}
      <HostLocationOnMap
        data={allHosts}
        onSetMap={currentMap => {
          mapRef.current = currentMap
        }}
      />
      {isLoadingAllHosts ? (
        <p css="text-align: center; margin-top: 1.56rem;">
          <Fa icon="sync" spin />
        </p>
      ) : null}
      {allHosts?.length > 0
        ? allHosts.map(x => (
            <HostListItem
              isHost={isRegisteredAsHost}
              key={JSON.stringify(x)}
              data={x}
              onViewOnMap={() => {
                mapRef.current.flyTo({
                  center: [
                    Number(x.location.coords.lng),
                    Number(x.location.coords.lat),
                  ],
                  zoom: 15,
                })
              }}
              onPressJoin={() => {
                setSelectedHost({
                  ...x,
                  hostId: x.host_id,
                })
                setModalVisible(true)
              }}
            />
          ))
        : null}
      {!isLoadingAllHosts && allHosts?.length === 0 ? (
        <p
          css={`
            text-align: center;
            border: 2px solid ${p => p.theme.colors.danger};
            padding: 1em;
          `}
        >
          There are no current available local hosts at NMC.
        </p>
      ) : null}
    </Layout>
  )
}

export default AttendeePage
