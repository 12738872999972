import { useEffect, useState } from "react"

function useLocation() {
  const [isLoading, setIsLoading] = useState(true)
  const [location, setLocation] = useState()
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!window || !window.navigator.geolocation) {
      return
    }

    window.navigator.geolocation.getCurrentPosition(
      pos => {
        setLocation({ lat: pos.coords.latitude, lng: pos.coords.longitude })
        setIsLoading(false)
      },
      err => {
        setError(err.message)
        setIsLoading(false)
      }
    )
  }, [])

  return {
    isLoading,
    coords: location,
    error,
  }
}

export default useLocation
