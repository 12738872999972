import mapboxgl from "!mapbox-gl" // eslint-disable-line import/no-webpack-loader-syntax
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"
import "mapbox-gl/dist/mapbox-gl.css"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { growOverParentPadding, media } from "../../../../styles"
import useLocation from "../../hooks/useLocation"

mapboxgl.accessToken = process.env.GATSBY_MAPBOX_ACCESS_TOKEN

const MapContainer = styled.div`
  height: 320px;

  margin-bottom: ${1.56 / 2}rem;

  .mapboxgl-marker {
    cursor: pointer;
  }

  ${media.extraSmall`
    ${growOverParentPadding(96)}
  `}
`

const HostLocationOnMap = ({ data, onSetMap }) => {
  const {
    isLoading: isLoadingLocation,
    coords,
    error: locationError,
  } = useLocation()

  const mapContainer = useRef(null)
  const map = useRef(null)
  const markers = useRef([])

  useEffect(() => {
    if (isLoadingLocation === false) {
      if (coords) {
        // draw a marker
        const myLocationMarker = new mapboxgl.Marker({ color: "red" })
        myLocationMarker.setLngLat([coords.lng, coords.lat]).addTo(map.current)
        markers.current.push(myLocationMarker)

        // move the map to my current location
        map.current.flyTo({
          center: [coords.lng, coords.lat],
          zoom: 8,
        })
      }

      if (locationError) {
        // move the map to my current location
        map.current.flyTo({
          center: [0, 0],
          zoom: 0,
        })
      }
    }
  }, [coords, isLoadingLocation, locationError])

  useEffect(() => {
    // initialize map only once
    if (map.current) {
      return
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [0, 0],
      zoom: 8,
    })

    onSetMap(map.current)

    map.current.on("click", e => {
      console.log("click event", e)
    })

    // // create marker for initial posiion and registered data
    // markers.current = new mapboxgl.Marker()
  }, [onSetMap])

  useEffect(() => {
    if (data) {
      data.forEach(x => {
        const newMarker = new mapboxgl.Marker()
        newMarker
          .setLngLat([
            Number(x.location.coords.lng),
            Number(x.location.coords.lat),
          ])
          .addTo(map.current)
        markers.current.push(newMarker)
      })
    }
  }, [data])

  return <MapContainer ref={mapContainer} />
}

HostLocationOnMap.propTypes = {
  onSetMap: PropTypes.func.isRequired,
}

export default HostLocationOnMap
