import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { ErrorMessage, useForm } from "react-hook-form"
import styled, { createGlobalStyle } from "styled-components"
import {
  ButtonsContainer,
  FormButton,
} from "../../../../components/BaseComponents/Buttons"
import {
  FormContainer,
  InputContainer,
  RequiredIcon,
  WarningMessage,
} from "../../../../components/FormComponents"
import { media } from "../../../../styles"
import { color, Fa } from "../../../../utils"

const LockScrollStyle = createGlobalStyle`
  html, body {
    overflow-x: hidden;
    overflow-y: ${props => (props.shouldLock ? "hidden" : "auto")}
  }
`

const ModalContainer = styled.div`
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${p => color.transparentize(p.theme.colors.primary, 0.75)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  /* on mobile, show mocal close button instead */
  ${media.extraSmall`
    z-index: 99999;
    overflow-y: hidden;
  `}
`

const CloseModalButton = styled(Fa).attrs(() => ({
  icon: "times",
}))`
  color: ${p => p.theme.colors.danger};

  position: absolute;
  top: 15px;
  right: 15px;

  &:active {
    color: indianred;
  }
`

const StyledFormContainer = styled(FormContainer)`
  flex: none;
  height: auto;
  background-color: ${p => p.theme.colors.primary};
  position: relative;
`

const AttendeeForm = ({ modalControl, hostData, onSubmit }) => {
  const [modalVisible, setModalVisible] = modalControl
  const { firstname, lastname, institution, location, hostId } = hostData

  const { register, handleSubmit, errors, setValue } = useForm()
  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    register("host_id")
    setValue("host_id", hostId)
  }, [hostId, register, setValue])

  return (
    <>
      <LockScrollStyle shouldLock={modalVisible} />
      <ModalContainer visible={modalVisible}>
        <StyledFormContainer>
          <CloseModalButton onClick={() => setModalVisible(false)} />
          <form
            onSubmit={handleSubmit(data => {
              onSubmit(data, () => setIsSending(true))
            })}
          >
            <InputContainer>
              <label>Host name</label>
              <p css="margin: 0;">{`${firstname} ${lastname} (${institution})`}</p>
            </InputContainer>
            <InputContainer>
              <label>Location</label>
              <p css="margin: 0;">{location?.place_name}</p>
            </InputContainer>
            <InputContainer>
              <label>
                Academic interests:
                <RequiredIcon />
              </label>
              <textarea
                rows="3"
                type="text"
                name="interests"
                ref={register({
                  required: "Academic interests is required.",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="interests"
                as={<WarningMessage />}
              />
            </InputContainer>
            <InputContainer>
              <label>
                Anything else you would like to tell the host?:
                <RequiredIcon />
              </label>
              <textarea
                rows="3"
                type="text"
                name="message"
                ref={register({
                  required: "Message to the host is required.",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="message"
                as={<WarningMessage />}
              />
            </InputContainer>
            <ButtonsContainer>
              <FormButton
                as="input"
                value={isSending ? "Submitting.." : "Join this meetup"}
                disabled={isSending}
              />
            </ButtonsContainer>
          </form>
        </StyledFormContainer>
      </ModalContainer>
    </>
  )
}

AttendeeForm.propTypes = {
  modalControl: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
  ),
  hostData: PropTypes.shape({
    hostId: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    institution: PropTypes.string,
    location: PropTypes.object,
  }),
  onSubmit: PropTypes.func,
}

export default AttendeeForm
