import PropTypes from "prop-types"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import {
  ButtonsContainer,
  LineButton,
} from "../../../../components/BaseComponents/Buttons"
import { common, Fa } from "../../../../utils"

const Container = styled.div`
  border: 1px solid
    ${p =>
      p.status === "Accepted"
        ? p.theme.colors.succeed
        : p.status === "Rejected"
        ? p.theme.colors.danger
        : p.status === "Pending"
        ? p.theme.colors.warning
        : p.theme.colors.secondary};
  border-radius: 4px;

  padding: 8px 14px;
  margin-bottom: ${1.56 / 2}rem;

  dd {
    font-size: 0.9em;
    margin-bottom: ${1.56 / 3}rem;
  }

  position: relative;
  &::after {
    content: ${p =>
      p.status ? `"${p.status}"` : `"${p.distance?.toFixed(2) || "-"} mi"`};
    position: absolute;
    top: -0.9em;
    right: 5px;
    font-size: 0.75em;
    font-weight: bold;
    padding: 0 4px;
    background-color: ${p => p.theme.colors.primary};
    color: ${p =>
      p.status === "Accepted"
        ? p.theme.colors.succeed
        : p.status === "Rejected"
        ? p.theme.colors.danger
        : p.status === "Pending"
        ? p.theme.colors.warning
        : p.theme.colors.secondary};
  }
`

// make the second line indent
const DetailedBlock = styled.div`
  padding-left: 1.56em;
  text-indent: -1.56em;
`

// eslint-disable-next-line react/prop-types
const TopicHeading = ({ children }) => <b>{`${children}: `}</b>

const StyledButtonsContainer = styled(ButtonsContainer)`
  display: flex;
  justify-content: flex-end;

  margin-top: 0;
`

const StyledButton = styled(LineButton)`
  font-size: 0.9em;
  padding: 3px 9px;
`

const HostListItem = ({
  data,
  onViewOnMap,
  onPressJoin,
  isHost,
  appliedStatus,
  myLocation,
}) => {
  const {
    firstname,
    lastname,
    institution,
    capacity,
    facilities,
    location,
    distance,
    // more info
    detailed_location,
    contact_info,
    security,
    socials,
  } = data

  const [displayMoreInfo, setDisplayMoreInfo] = useState(false)

  return (
    <Container distance={distance} status={appliedStatus}>
      <dt>{`${firstname} ${lastname} (${institution})`}</dt>
      <dd>
        {location?.place_name ? (
          <DetailedBlock>
            <TopicHeading>Location</TopicHeading>
            {location?.place_name}
            <br />
          </DetailedBlock>
        ) : null}
        {capacity ? (
          <DetailedBlock>
            <TopicHeading>Capacity</TopicHeading>
            {capacity}
            <br />
          </DetailedBlock>
        ) : null}
        {facilities ? (
          <DetailedBlock>
            <TopicHeading>Facilities</TopicHeading>
            {facilities}
            <br />
          </DetailedBlock>
        ) : null}
        {displayMoreInfo ? (
          <>
            {detailed_location ? (
              <DetailedBlock>
                <TopicHeading>Detailed Location</TopicHeading>
                {detailed_location}
                <br />
              </DetailedBlock>
            ) : null}
            {contact_info ? (
              <DetailedBlock>
                <TopicHeading>Contact Information</TopicHeading>
                {contact_info}
                <br />
              </DetailedBlock>
            ) : null}
            {security ? (
              <DetailedBlock>
                <TopicHeading>Security</TopicHeading>
                {security}
                <br />
              </DetailedBlock>
            ) : null}
            {socials ? (
              <DetailedBlock>
                <TopicHeading>Socials</TopicHeading>
                {socials}
                <br />
              </DetailedBlock>
            ) : null}
          </>
        ) : null}
      </dd>
      <StyledButtonsContainer>
        {appliedStatus === "Accepted" ? (
          <>
            <StyledButton
              title="View more information"
              onClick={e => {
                e.stopPropagation()
                setDisplayMoreInfo(prev => !prev)
              }}
            >
              <Fa icon={`caret-${displayMoreInfo ? "up" : "down"}`} />
            </StyledButton>
            <StyledButton
              css={css`
                &:hover {
                  color: ${p => p.theme.colors.secondary};
                }
              `}
              as="a"
              title="Get directions"
              href={
                !myLocation.lat || !myLocation.lng
                  ? `https://www.google.com/maps/search/?api=1&query=${location.coords.lat},${location.coords.lng}`
                  : `https://www.google.com/maps/dir/?api=1&origin=${myLocation.lat},${myLocation.lng}&destination=${location.coords.lat},${location.coords.lng}`
              }
              rel="noopener noreferrer"
              target="_blank"
            >
              <Fa icon="map-marked" />
            </StyledButton>
          </>
        ) : null}
        <StyledButton
          title="View on map"
          onClick={e => {
            e.stopPropagation()
            onViewOnMap()

            const canvas = document.querySelector(".mapboxgl-canvas")
            canvas.scrollIntoView({ behavior: "smooth", block: "center" })
          }}
        >
          <Fa icon="map-marker-alt" />
        </StyledButton>
        {!appliedStatus ? (
          <StyledButton
            disabled={isHost}
            title={
              isHost
                ? "You have already registerd as a host, you can't register as an attendee."
                : "Join this meetup!"
            }
            onClick={e => {
              e.stopPropagation()
              onPressJoin()
            }}
          >
            Join
          </StyledButton>
        ) : null}
      </StyledButtonsContainer>
    </Container>
  )
}

HostListItem.propTypes = {
  onViewOnMap: PropTypes.func.isRequired,
  onPressJoin: PropTypes.func.isRequired,
  isHost: PropTypes.bool,
  appliedStatus: PropTypes.oneOf(["Pending", "Accepted", "Rejected"]),
  myLocation: PropTypes.shape({
    lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
}

HostListItem.defaultProps = {
  isHost: true,
  appliedInfo: null,
  myLocation: {},
}

export default HostListItem
