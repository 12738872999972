import { useEffect, useState } from "react"
import useAPI from "../../../hooks/useAPI"

function useValidateMeetupStatus() {
  const { getUserData } = useAPI()
  const [isRegisteredAsHost, setIsRegisteredAsHost] = useState(null)
  const [isRegisteredAsAttendee, setIsRegisteredAsAttendee] = useState(null)
  const [hostId, setHostId] = useState(null)
  const [attendeeId, setAttendeeId] = useState(null)

  useEffect(() => {
    const getUserPromise = getUserData()

    if (!getUserPromise) {
      return
    }

    getUserPromise
      .then(res => res.json())
      .then(resJson => {
        if (resJson?.data?.meetup?.host_id) {
          setHostId(resJson.data.meetup.host_id)
          if (resJson?.data?.meetup?.as === "host") {
            setIsRegisteredAsHost(true)
            setIsRegisteredAsAttendee(false)
          }
          if (resJson?.data?.meetup?.as === "attendee") {
            setAttendeeId(resJson?.data?.meetup?.attendee_id)
            setIsRegisteredAsHost(false)
            setIsRegisteredAsAttendee(true)
          }
        } else {
          setIsRegisteredAsHost(false)
          setIsRegisteredAsAttendee(false)
        }
      })
  }, [getUserData])

  return {
    isRegisteredAsHost,
    isRegisteredAsAttendee,
    hostId,
    attendeeId,
  }
}

export default useValidateMeetupStatus
